import React from 'react';
import { BrowserRouter, Route ,Switch} from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'

// 各コンポーネント
import DrawerMenu from './DrawerMenu';
import PostInfo from './PostInfo';
import PostFes from './PostFes';
import PostFesUpdate from './PostFesUpdate';

const App = () => {
  return (
    <BrowserRouter>
      <CssBaseline />
      <DrawerMenu>
        <Switch>
          <Route path='/postFes' component={PostFes} />
          <Route path='/PostFesUpdate' component={PostFesUpdate} />
          <Route path='/postInfo' component={PostInfo} />
        </Switch>
      </DrawerMenu>
    </BrowserRouter>
  );
}

export default App;
