import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';

const types = [
  {
    value: 'N',
    label: '新着お祭り',
  },
  {
    value: 'I',
    label: 'イベント',
  },
  {
    value: 'Z',
    label: 'その他',
  },
];

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width:'100%'
  },
  textField_m: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width:'45%'
  },
  textField_s: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width:'35%'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },

  graylist: {
    backgroundColor: "#CCCCCC",
  },
  redlist: {
    backgroundColor: "#FFCCCC",
  },
  whitelist: {
    backgroundColor: "#FFFFFF",
  },

}));



function ListDialog(props) {
  const classes = useStyles();
  const [infolist, setList] = React.useState([]);


  function getList() {
    const url = "https://www.hinasora.com/api/fes/getInfoListForAdm.php";

    fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
        return response;
      })
      .then((response) => response.json())
      .then((resjson) =>  {
        setList(resjson);
        }
      )
      .catch(() => alert("error"));
  }

  return (
      <Dialog fullScreen disableEscapeKeyDown open={props.open} onEnter={getList} onClose={() => props.close(null)}>
        <div>
          <List component="nav" aria-label="">
            <ListItem button onClick={() => {props.close(null)}}><ListItemText primary="Close" /></ListItem>
          </List>
          <List component="nav" aria-label="">
            <ListItem button onClick={() => {props.close([])}}><ListItemText primary="N e w" /></ListItem>
          </List>
          <Divider />
          <List aria-label="" disablePadding dense>
            {infolist.map((info) => (
            <ListItem button key={info.infoId} divider
              className={info.infoDelete ? classes.graylist : info.infoAdmin ? classes.redlist : classes.whitelist}
              onClick={() => props.close(info)} >
              <ListItemText primary={'['+ info.infoId +'] ' + info.infoTitle} />
            </ListItem>
            ))}
          </List>

        </div>
      </Dialog>
  );
}

const PostInfo = () => {
  const classes = useStyles();

  const getDate = (addDay) => {
    var date = new Date();
    date.setDate(date.getDate() + addDay);
    var year  = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2)
    var day   = ('0' + date.getDate()).slice(-2);
    return String(year) + "-" + String(month) + "-" + String(day);
  }

  const [values, setValues] = React.useState({
    infoId: 0,
    infoTitle: '',
    infoTagName: '',
    infoType: 'N',
    infoAvatar: '',
    infoStart: getDate(0),
    infoEnd: getDate(3),
    infoCaption: '',
    infoDelete: 0,
    infoAdmin : 1,
    open      :false,
  });

  const [message, setMessage] = React.useState("");


  const handleChange = name => event => {
    const newvalues = { ...values, [name] : event.target.value,}
    setValues({ ...newvalues, });
  };

  const sendRequest = () => {
    let url = "https://www.hinasora.com/api/fes/updateInfo.php";

    if (values.infoId === 0) {
      url = "https://www.hinasora.com/api/fes/insertInfo.php";
    }
    
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
        return response;
      })
      .then((response) => response.json())
      .then((resjson) => setMessage(resjson.message))
      .catch(() => setMessage("error"));
  };


  function dialogClose(info) {

    if(info === null){
      setValues(oldValues => ({
        ...oldValues,
        'open': false,
        }));

      return;
    }

    if(info.length === 0){
      setValues(oldValues => ({
        ...oldValues,
        infoId: 0,
        infoTitle: '',
        infoTagName: '',
        infoType: 'N',
        infoAvatar: '',
        infoStart: getDate(0),
        infoEnd: getDate(3),
        infoCaption: '',
        infoDelete: 0,
        infoAdmin : 1,
        open      :false,
      }));

      return;
    }

    setValues(oldValues => ({
      ...oldValues,
      infoId: info.infoId,
      infoTagName: info.infoTagName,
      infoTitle: info.infoTitle,
      infoCaption: info.infoCaption,
      infoType: info.infoType,
      infoAvatar: info.infoAvatar,
      infoStart: info.infoStart,
      infoEnd: info.infoEnd,
      infoDelete: info.infoDelete,
      infoAdmin: info.infoAdmin,
      'open': false,
      }));

  }


  return (
    <div className={classes.container} noValidate autoComplete="off">
      <Button
          variant="contained"
          className={classes.button}
          onClick={() => setValues(oldValues => ({ ...oldValues, 'open': true,}))}
        >
        編集対象の選択
      </Button>

      <Typography>[{values.infoId}] {values.infoTitle}</Typography>

      <TextField
        id="Title"
        label="Title"
        required
        placeholder="Title"
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={values.infoTitle}
        onChange={handleChange('infoTitle')}
      />

      <TextField
        id="TagName"
        label="TagName"
        placeholder="TagName"
        className={classes.textField}
        margin="normal"
        variant="outlined"
        InputProps={{
          startAdornment: <InputAdornment position="start">#</InputAdornment>,
        }}
        value={values.infoTagName}
        onChange={handleChange('infoTagName')}
      />

      <TextField
        id="Type"
        select
        label="Info Type"
        value={values.infoType}
        className={classes.textField_m}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="outlined"
        onChange={handleChange('infoType')}
      >
        {types.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="Avatar"
        label="Avatar"
        placeholder="Avatar"
        className={classes.textField_m}
        margin="normal"
        variant="outlined"
        value={values.infoAvatar}
        onChange={handleChange('infoAvatar')}
      />
      <TextField
        id="FromDate"
        label="Date (From)"
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        variant="outlined"
        value={values.infoStart}
        onChange={handleChange('infoStart')}
      />
      <TextField
        id="ToDate"
        label="Date (To)"
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        variant="outlined"
        value={values.infoEnd}
        onChange={handleChange('infoEnd')}
      />
      <TextField
        id="Caption"
        label="Caption"
        placeholder="Caption"
        multiline
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={values.infoCaption}
        onChange={handleChange('infoCaption')}
      />

<TextField
        id="infoAdmin"
        select
        required
        label="infoAdmin"
        value={values.infoAdmin}
        className={classes.textField_s}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="outlined"
        onChange={handleChange('infoAdmin')}
      >
        <MenuItem value={0}>有効</MenuItem>
        <MenuItem value={1}>隠し</MenuItem>
      </TextField>

      <TextField
        id="infoDelete"
        select
        required
        label="infoDelete"
        value={values.infoDelete}
        className={classes.textField_s}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="outlined"
        onChange={handleChange('infoDelete')}
      >
        <MenuItem value={0}>有効</MenuItem>
        <MenuItem value={1}>削除</MenuItem>
      </TextField>


      <Button
        variant="contained"
        className={classes.button}
        disabled={values.buttonDisabl}
        onClick={() => sendRequest()}
      >
        Send
        <SendIcon className={classes.rightIcon}>send</SendIcon>
      </Button>
      <p>{message}</p>
      <ListDialog
      open={values.open}
      close={(info) => {dialogClose(info)}}
      />

    </div>
  );
}

export default PostInfo;
