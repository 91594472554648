import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';

const types = [
  {value: 'fes',label: 'お祭り・イベント',},
  {value: 'slogan',label: '合言葉',},
  {value: 'group',label: 'クラブ・グループ',},
  {value: 'charity',label: 'チャリティー',},
  {value: 'contest',label: 'コンテスト',},
  {value: 'other',label: 'その他',},
];

const priorities = [
  {value: 1,label: '1日限り',},
  {value: 2,label: '期間限り',},
  {value: 3,label: '年1回',},
  {value: 4,label: '年数回',},
  {value: 5,label: '月1回',},
  {value: 6,label: '月数回',},
  {value: 7,label: '週1回',},
  {value: 8,label: '週数回',},
  {value: 9,label: '毎日',},
];

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width:'100%'
  },
  textField_m: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width:'45%'
  },
  textField_s: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width:'35%'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  graylist: {
    backgroundColor: "#CCCCCC",
  },
  redlist: {
    backgroundColor: "#FFCCCC",
  },
  whitelist: {
    backgroundColor: "#FFFFFF",
  },

}));


function ListDialog(props) {
  const classes = useStyles();
  const [feslist, setList] = React.useState([]);


  function getList() {
    const url = "https://www.hinasora.com/api/fes/getFesListForAdm.php";

    fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
        return response;
      })
      .then((response) => response.json())
      .then((resjson) =>  {
        setList(resjson);
        }
      )
      .catch(() => alert("error"));
  }

  return (
      <Dialog fullScreen disableEscapeKeyDown open={props.open} onEnter={getList} onClose={() => props.close(null)}>
        <div>
          <List component="nav" aria-label="">
            <ListItem button onClick={() => {props.close(null)}}><ListItemText primary="Close" /></ListItem>
          </List>
          <Divider />
          <List aria-label="" disablePadding dense>
            {feslist.map((fes) => (
            <ListItem button key={fes.fesId} divider
              className={fes.sysDeleteFlg ? classes.graylist : fes.sysAdmin ? classes.redlist : classes.whitelist}
              onClick={() => props.close(fes.fesTagName)} >
              <ListItemText primary={'['+ fes.fesId +'] ' + fes.fesTagName} />
            </ListItem>
            ))}
          </List>

        </div>
      </Dialog>
  );
}


const PostFesUpdate = () => {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    fesId: 0,
    fesType: '',
    fesSubType: '',
    fesName: '',
    fesTagName: '',
    fesTagCopy: '',
    fesSchedule: '',
    fesOrgid: '',
    fesOrgname: '',
    fesStart: '',
    fesEnd: '',
    fesDescription: '',
    fesDetail: '',
    fesRelate: '',
    srchSchedule: '',
    srchWord: '',
    srchExclusion: '',
    sortPriority: 5,
    sysDeleteFlg:0,
    sysAdmin:0,

    open   : false,
  });

  const [message, setMessage] = React.useState("");

  const handleChange = name => event => {
    const newvalues = { ...values, [name] : event.target.value,}

    setValues({ ...newvalues,});
  };

  function postRequest() {
    let postvalues = { ...values}
    console.info(postvalues);

    let url =  "https://www.hinasora.com/api/fes/updateFes.php";
    if(values.fesId === 0){
      url = "https://www.hinasora.com/api/fes/insertFes.php";
    }

    fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(postvalues),
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
        return response;
      })
      .then((response) => response.json())
      .then((resjson) => setMessage(resjson.message))
      .catch(() => setMessage("error"));
  }

  function dialogClose(tagName) {

    if(tagName === null){
      setValues(oldValues => ({
        ...oldValues,
        'open': false,
        }));

      return;
    }

    const url = "https://www.hinasora.com/api/fes/getFesByTagName.php?tag=" + tagName;

    fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
        return response;
      })
      .then((response) => response.json())
      .then((resjson) =>  {
          setMessage("");
          if (resjson.length > 0) {
            const fes = resjson[0];
            setValues(oldValues => ({
              ...oldValues,
              fesId: fes.fesId,
              fesType: fes.fesType,
              fesSubType: fes.fesSubType,
              fesName: fes.fesName,
              fesTagName: fes.fesTagName,
              fesTagCopy: fes.fesTagCopy,
              fesSchedule: fes.fesSchedule,
              fesOrgid: fes.fesOrgid,
              fesOrgname: fes.fesOrgname,
              fesStart: fes.fesStart,
              fesEnd: fes.fesEnd,
              fesDescription: fes.fesDescription,
              fesDetail: fes.fesDetail,
              fesRelate: fes.fesRelate,
              srchSchedule: fes.srchSchedule,
              srchWord: fes.srchWord,
              srchExclusion: fes.srchExclusion,
              sortPriority: fes.sortPriority,
              sysDeleteFlg: fes.sysDeleteFlg,
              sysAdmin: fes.sysAdmin,

              'open': false,
              }));
  
          }
        }
      )
      .catch(() => setMessage("error"));
  }

  return (
    <React.Fragment>

    <div className={classes.container}>
      <Button
          variant="contained"
          className={classes.button}
          onClick={() => setValues(oldValues => ({ ...oldValues, 'open': true,}))}
        >
        編集対象の選択
      </Button>

      <Typography>[{values.fesId}] {values.fesTagName}</Typography>

      <TextField
          id="fesTagName"
          label="TagName"
          required
          placeholder="TagName"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">#</InputAdornment>,
          }}
          value={values.fesTagName}
          onChange={handleChange('fesTagName')}
        />

      <TextField
              id="fesName"
              label="Title"
              placeholder="Title"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={values.fesName}
              onChange={handleChange('fesName')}
            />

      <TextField
              id="fesTagCopy"
              label="fesTagCopy"
              placeholder="fesTagCopy"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={values.fesTagCopy}
              onChange={handleChange('fesTagCopy')}
            />

      <TextField
        id="fesSchedule"
        label="Schedule"
        required
        placeholder="Schedule"
        className={classes.textField_m}
        margin="normal"
        variant="outlined"
        value={values.fesSchedule}
        onChange={handleChange('fesSchedule')}
      />

      <TextField
        id="srchSchedule"
        label="Schedule(search)"
        placeholder="m01m w0w y0805y"
        className={classes.textField_m}
        margin="normal"
        variant="outlined"
        value={values.srchSchedule}
        onChange={handleChange('srchSchedule')}
      />

      <TextField
        id="fesStart"
        label="Date (From)"
        type="date"
        className={classes.textField_m}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        variant="outlined"
        value={values.fesStart}
        onChange={handleChange('fesStart')}
      />

      <TextField
        id="fesEnd"
        label="Date (To)"
        type="date"
        className={classes.textField_m}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        variant="outlined"
        value={values.fesEnd}
        onChange={handleChange('fesEnd')}
      />

      <TextField
        id="fesType"
        select
        required
        label="Type"
        value={values.fesType}
        className={classes.textField_m}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="outlined"
        onChange={handleChange('fesType')}
      >
        {types.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="fesSubType"
        label="fesSubType"
        placeholder=""
        className={classes.textField_m}
        margin="normal"
        variant="outlined"
        value={values.fesSubType}
        onChange={handleChange('fesSubType')}
      />

      <TextField
        id="fesDescription"
        label="Explain"
        placeholder="Explain"
        multiline
        required
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={values.fesDescription}
        onChange={handleChange('fesDescription')}
      />

      <TextField
        id="fesDetail"
        label="Detail"
        placeholder="Detail"
        multiline
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={values.fesDetail}
        onChange={handleChange('fesDetail')}
      />

<TextField
        id="srchWord"
        label="Keywords"
        placeholder="Keywords"
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={values.srchWord}
        onChange={handleChange('srchWord')}
      />

      <TextField
        id="srchExclusion"
        label="Exclusion"
        placeholder="Exclusion"
        className={classes.textField}
        margin="normal"
        variant="outlined"
        value={values.srchExclusion}
        onChange={handleChange('srchExclusion')}
      />

<TextField
        id="sortPriority"
        select
        required
        label="Sort"
        value={values.sortPriority}
        className={classes.textField_m}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="outlined"
        onChange={handleChange('sortPriority')}
      >
        {priorities.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

<TextField
        id="sysAdmin"
        select
        required
        label="sysAdmin"
        value={values.sysAdmin}
        className={classes.textField_s}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="outlined"
        onChange={handleChange('sysAdmin')}
      >
        <MenuItem value={0}>有効</MenuItem>
        <MenuItem value={1}>隠し</MenuItem>
      </TextField>

      <TextField
        id="sysDeleteFlg"
        select
        required
        label="sysDeleteFlg"
        value={values.sysDeleteFlg}
        className={classes.textField_s}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="outlined"
        onChange={handleChange('sysDeleteFlg')}
      >
        <MenuItem value={0}>有効</MenuItem>
        <MenuItem value={1}>削除</MenuItem>
      </TextField>

      <Button
        variant="contained"
        className={classes.button}
        onClick={() => postRequest()}
      >
        Send
        <SendIcon className={classes.rightIcon}>send</SendIcon>
      </Button>
      <p>{message}</p>
    </div>
    <ListDialog
      open={values.open}
      close={(tagName) => {dialogClose(tagName)}}
      />
    </React.Fragment>
  );

}

export default PostFesUpdate;
