import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';

const types = [
  {value: 'fes',label: 'お祭り・イベント',},
  {value: 'slogan',label: '合言葉',},
  {value: 'group',label: 'クラブ・グループ',},
  {value: 'charity',label: 'チャリティー',},
  {value: 'contest',label: 'コンテスト',},
  {value: 'other',label: 'その他',},
];

const useStyles = makeStyles(theme => ({
  container: {
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width:'100%',
  },
  textField_m: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width:'45%',
  },
  textField_s: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width:'45%',
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const PostFes = () => {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    fesName: '',
    fesTagName: '',
    fesTagCopy: '',
    fesSchedule: '',
    fesOrgid: '',
    fesOrgname: '',
    fesStart: '',
    fesEnd: '',
    fesDescription: '',
    fesDetail: '',
    fesRelate: '',
    fesType: 'fes',
    fesSubType: '',
    srchSchedule: '',
    srchWord: '',
    srchExclusion: '',
    buttonDisabl: true,
  });

  const [message, setMessage] = React.useState("");

  const handleChange = name => event => {
    const newvalues = { ...values, [name] : event.target.value,}

    setValues({ ...newvalues,
              buttonDisabl : !( newvalues.fesType !== ""
              && newvalues.fesTagName !== ""
              && newvalues.fesSchedule !== ""
              && newvalues.fesDescription !== ""
               )
              });
  };

  const sendRequest = () => {
    
    let postvalues = { ...values}
    
    if(postvalues.fesName === '') {postvalues.fesName = postvalues.fesTagName;}
    if(postvalues.fesTagCopy === '') {postvalues.fesTagCopy = '#' + postvalues.fesTagName;}

    console.info(postvalues);
    fetch('https://www.hinasora.com/api/fes/postFes.php', {
        method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postvalues),
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
        return response;
      })
      .then((response) => response.json())
      .then((resjson) => setMessage(resjson.message))
      .catch(() => setMessage("error"));
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">

      <TextField
          id="fesTagName"
          label="TagName"
          required
          placeholder="TagName"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">#</InputAdornment>,
          }}
          onChange={handleChange('fesTagName')}
        />

      <TextField
        id="fesType"
        select
        required
        label="Type"
        value={values.fesType}
        className={classes.textField_m}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="outlined"
        onChange={handleChange('fesType')}
      >
        {types.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="fesStart"
        label="Date (From)"
        type="date"
        className={classes.textField_m}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        variant="outlined"
        onChange={handleChange('fesStart')}
      />

      <TextField
        id="fesSchedule"
        label="Schedule"
        required
        placeholder="Schedule"
        className={classes.textField_m}
        margin="normal"
        variant="outlined"
        onChange={handleChange('fesSchedule')}
      />

      <TextField
        id="srchSchedule"
        label="Schedule(search)"
        placeholder="m01m w0w y0805y"
        className={classes.textField_m}
        margin="normal"
        variant="outlined"
        onChange={handleChange('srchSchedule')}
      />


      <TextField
        id="fesDescription"
        label="Explain"
        placeholder="Explain"
        multiline
        required
        className={classes.textField}
        margin="normal"
        variant="outlined"
        onChange={handleChange('fesDescription')}
      />

      <Button
        variant="contained"
        className={classes.button}
        disabled={values.buttonDisabl}
        onClick={() => sendRequest()}
      >
        Send
        <SendIcon className={classes.rightIcon}>send</SendIcon>
      </Button>

      <TextField
        id="srchWord"
        label="Keywords"
        placeholder="Keywords"
        className={classes.textField}
        margin="normal"
        variant="outlined"
        onChange={handleChange('srchWord')}
      />


      <TextField
        id="fesDetail"
        label="Detail"
        placeholder="Detail"
        multiline
        className={classes.textField}
        margin="normal"
        variant="outlined"
        onChange={handleChange('fesDetail')}
      />


      <p>{message}</p>
    </form>
  );
}

export default PostFes;
